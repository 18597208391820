export const uniqueName = (name) => {
  const extension = name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2);
  const timestamp = new Date().getTime();
  const randomValue = Math.floor(Math.random() * 100000);
  const fileName = `${timestamp}_${randomValue}.${extension}`;
  const sanitizedFileName = fileName.replace(/\s+/g, "_");
  return sanitizedFileName;
};

export function validateVideo(selectedFile) {
  if (!selectedFile.type.startsWith("video/")) {
    return "Please select a valid video file";
  } else {
    return "ok";
  }
}
export const getDateRange = () => {
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);

  const formattedToday = today.toISOString().split("T")[0];
  const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];
  return { from: formattedSevenDaysAgo, to: formattedToday };
};
