import axios from "../util/axios";

export const getUsers = async (
  status,
  search,
  currentPage,
  subscriptionStatus
) => {
  try {
    const perPageContent = 10;
    const offset = (+currentPage - 1) * +perPageContent;
    const params = {
      offset,
      limit: perPageContent,
      sort: JSON.stringify([["created_at", "desc"]]),
      populate: JSON.stringify(["subscription"]),
    };
    const whereCondition = {
      role: "User",
    };
    if (search !== "") {
      params.search = search;
    }
    if (status !== null) {
      whereCondition.active = status;
    }

    if (subscriptionStatus !== null) {
      switch (subscriptionStatus) {
        case null:
          break;
        case "Subscribed":
          whereCondition.subscription_date = { $ne: null };
          break;
        case "Unsubscribed":
          whereCondition["$subscription.is_subscribed$"] = "N";
          break;
        case "Not Subscribed":
          whereCondition["$subscription.id$"] = null;
          break;
        default:
          break;
      }
    }
    params.where = JSON.stringify(whereCondition);
    const response = await axios.get("/user", { params });
    return response?.data?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.error || "Something went wrong");
  }
};

export const getUser = async (id) => {
  try {
    const response = await axios.get(`/user/${id}`, {
      params: {
        populate: JSON.stringify(["subscription"]),
      },
    });
    return response?.data?.data;
  } catch (error) {
    if (error?.response?.status === 404) {
      throw new Error("404");
    } else {
      throw new Error(error);
    }
  }
};

export const putUser = async (id, data) => {
  try {
    const response = await axios.put(`/user/${id}`, { active: data });
    return response?.data?.data?.user;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else {
      throw new Error(error?.response?.data?.error || "Something went wrong");
    }
  }
};

//history
export const getPaymentHistory = async (userId, currentPage) => {
  try {
    const perPageContent = 10;
    const offset = (+currentPage - 1) * +perPageContent;
    const params = {
      offset,
      limit: perPageContent,
      sort: JSON.stringify([["created_at", "desc"]]),
    };
    const whereCondition = { user_id: userId };

    params.where = JSON.stringify(whereCondition);
    const response = await axios.get("/payment", { params });
    return response?.data?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.error || "Something went wrong");
  }
};
