import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardNavbar from "components/Navbar";
import DataTable from "components/Tables/DataTable";

// Data
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ContentLayout from "layouts/content";
import authorsTableData from "./data/authorsTableData";

import Loading from "components/Loading";
import Pagination from "components/Pagination";
import {
  // setCurrentPage,
  setTotalItems,
  useAdminPanelController,
} from "context";
import debounce from "lodash.debounce";
import { useEffect, useState } from "react";
import { getUsers } from "services/users";
import styles from "./styles.module.scss";

function Users() {
  const [controller, dispatch] = useAdminPanelController();
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const perPageContent = 10;
  const handleStatus = async (e) => {
    setStatus(e?.target?.value);
    setCurrentPage(1);
  };
  const handleSearch = debounce((e) => {
    setSearch(e?.target?.value);
    setCurrentPage(1);
  }, 400);
  const renderValue = (value) => {
    if (value === true) {
      return "Active";
    } else if (value === false) {
      return "Blocked";
    } else {
      return "";
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { users, count } = await getUsers(
          status,
          search,
          currentPage,
          subscriptionStatus
        );
        setUsers(users);
        setTotalItems(dispatch, count || null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, search, status, subscriptionStatus]);
  const { columns, rows } = authorsTableData(
    users,
    currentPage,
    perPageContent
  );

  return (
    <ContentLayout>
      <DashboardNavbar />
      {loading ? (
        <Loading />
      ) : (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Grid item xs={12} md={4}>
                      {" "}
                      <MDTypography variant="h6" color="white">
                        Users
                      </MDTypography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ display: "flex" }}
                      spacing={2}
                    >
                      {" "}
                      <FormControl fullWidth className={styles.custom_select}>
                        <InputLabel
                          id="demo-simple-select-label"
                          sx={{
                            backgroundColor: "#3C93EE",
                            padding: "0.2rem",
                          }}
                        >
                          Filter By Subscription
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={subscriptionStatus}
                          label="Age"
                          sx={{
                            padding: "12px",
                          }}
                          // renderValue={() => renderValue(subscriptionStatus)}
                          onChange={(e) => {
                            setSubscriptionStatus(e?.target?.value);
                            setCurrentPage(1);
                          }}
                        >
                          <MenuItem value={null}>None</MenuItem>
                          <MenuItem value={"Subscribed"}>Subscribed</MenuItem>
                          <MenuItem value={"Unsubscribed"}>
                            Unsubscribed
                          </MenuItem>
                          <MenuItem value={"Not Subscribed"}>
                            Not Subscribed
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        fullWidth
                        className={styles.custom_select}
                        sx={{
                          marginLeft: "1rem",
                        }}
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          sx={{
                            backgroundColor: "#3C93EE",
                            padding: "0.2rem",
                          }}
                        >
                          Filter By User Status
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={status}
                          label="Age"
                          sx={{
                            padding: "12px",
                          }}
                          renderValue={() => renderValue(status)}
                          onChange={handleStatus}
                        >
                          <MenuItem value={null}>None</MenuItem>
                          <MenuItem value={true}>Active</MenuItem>
                          <MenuItem value={false}>Blocked</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        id="demo-simple-search"
                        className={styles.custom_select}
                        sx={{
                          marginLeft: "1rem",
                          input: { color: "rgb(255, 255, 255)" },
                        }}
                        fullWidth
                        onChange={handleSearch}
                        label="Search"
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                  {users?.length === 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "1.5rem",
                      }}
                    >
                      <MDTypography
                        component="a"
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                      >
                        Oops !! No Result Found
                      </MDTypography>
                    </Box>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          {controller?.totalItems >= perPageContent && (
            <Pagination
              perPageContent={perPageContent}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          )}
        </MDBox>
      )}
    </ContentLayout>
  );
}

export default Users;
